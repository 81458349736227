import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Start = () => {
  return (
    <div className="bg-dark">
      <Helmet>
        <title>Web App Website Application APK </title>
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          name=" description"
        />
        <meta content="Web App Website Application APK " property="og:title" />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          property="og:description"
        />
        <meta
          content="https://1.bp.blogspot.com/-oolLYoq-j5Q/X93ym6YAD8I/AAAAAAAALus/NZ7SFbMNI543KZaqFx129dX0ST4MBQAQACLcBGAsYHQ/w1200-h630-p-k-no-nu/TOUCHSCREEN3.png"
          property="og:image"
        />
        <meta content="https://axcora.com/getaxcoracms" property="og:author" />
        <meta
          content="Web App Website Application APK "
          name="facebook:title"
        />
        <meta content="website" property="og:type" />
        <meta
          content="https://www.facebook.com/mesinkasircomplete"
          property="facebook:author"
        />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="facebook:image"
        />
        <meta
          content="Web App Website Application APK "
          property="og:site_name"
        />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          property="facebook:description"
        />
        <meta content="summary_large_image" name="twitter:card" />
        <meta
          content="https://twitter.com/hockeycomp"
          property="twitter:author"
        />
        <meta content="@hockeycomp" name="twitter:site" />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="twitter:image"
        />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          name="twitter:description"
        />
        <meta content="Web App Website Application APK " name="twitter:title" />
        <meta content="index, follow" name="robots" />
      </Helmet>
      <div className="col-12 col-md-12 p-3 text-center uk-animation-toggle uk-animation-scale-up">
        <img
          alt="Apk android mesin kasir online"
          className="img-fluid"
          width="300"
          src="https://1.bp.blogspot.com/-r0uUhjpe0hs/YC9zHWncIOI/AAAAAAAAM80/p-9VL5DmPVkNUzvZEczIu6_pRsuCPub8ACLcBGAsYHQ/s467/mesinkasironlineapp%2B%25281%2529.png"
        />
        <p>Hockey Co APK</p>
        <br />
        <Link to="/welcome" className="btn btn-primary">
          Get started
        </Link>
        <br />
        <br />
<p>
    Go Clouds with us
    </p>
      </div>
         <div className="col-12 col-md-12 p-3 p-md-5"></div>
         <div className="col-12 col-md-12 p-3 p-md-5"></div>
    </div>
  );
};
export default Start;
