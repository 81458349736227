import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Webapp = () => {
  return (
    <div className="bg-dark">
      <Helmet>
        <meta
          name="google-site-verification"
          content="ex4TnhMimzJT8jbjjo3HU_r-qfQczMhdYfALH1gyM2s"
        />
        <title>Web App Website Applikasi Online </title>
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          name=" description"
        />
        <meta content="Web App Website Applikasi Online " property="og:title" />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          property="og:description"
        />
        <meta
          content="https://1.bp.blogspot.com/-oolLYoq-j5Q/X93ym6YAD8I/AAAAAAAALus/NZ7SFbMNI543KZaqFx129dX0ST4MBQAQACLcBGAsYHQ/w1200-h630-p-k-no-nu/TOUCHSCREEN3.png"
          property="og:image"
        />
        <meta content="https://axcora.com/getaxcoracms" property="og:author" />
        <meta
          content="Web App Website Applikasi Online "
          name="facebook:title"
        />
        <meta content="website" property="og:type" />
        <meta
          content="https://www.facebook.com/mesinkasircomplete"
          property="facebook:author"
        />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="facebook:image"
        />
        <meta
          content="Web App Website Applikasi Online "
          property="og:site_name"
        />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          property="facebook:description"
        />
        <meta content="summary_large_image" name="twitter:card" />
        <meta
          content="https://twitter.com/hockeycomp"
          property="twitter:author"
        />
        <meta content="@hockeycomp" name="twitter:site" />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="twitter:image"
        />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          name="twitter:description"
        />
        <meta content="Web App Website Applikasi Online" name="twitter:title" />
        <meta content="index, follow" name="robots" />
      </Helmet>
      <div className="row uk-animation-toggle uk-animation-scale-up">
        <div className="col-12 col-md-12 p-3 p-md-5 text-center">
          <h3>Web Apps</h3>
          <hr className="uk-divider-icon" />
          <p>
            Pilih layanan web app website plus aplikasi dalam satu untuk
            kebutuhan modern technology
          </p>
          <div
            className="uk-position-relative uk-visible-toggle uk-dark p-3 p-md-5"
            tabIndex="-1"
            uk-slider="center: true"
          >
            <ul className="uk-slider-items uk-grid text-center">
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-h9xmyJqF1Y4/YC9zHfh8gKI/AAAAAAAAM8s/9YXLzcyK_gEjWhvd28gx3upKcMKSeEQugCLcBGAsYHQ/s400/mesinkasironlineapp%2B%25282%2529.png"
                    alt="aplikasi restoran,aplikasi mesin kasir online restroran cafe rumah makan"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">
                      Aplikasi Restoran Cafe
                    </h3>
                    <Link to="/restoapp" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-IhK7CRkB2f4/YC9zI-sLU0I/AAAAAAAAM9A/Ik2OkLYXE6kleqTDoqqfV_UWZvebOrq8wCLcBGAsYHQ/s400/mesinkasironlineapp%2B%25286%2529.png"
                    alt="aplikasi toko,aplikasi mesin kasir online"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">
                      Aplikasi Toko Shop
                    </h3>
                    <Link to="/apptoko" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-wpVlR-Xx8Mg/YC9zHUz3pDI/AAAAAAAAM8w/OisDsEtn6XA83iJqvY0NC8RgRzWKKcikwCLcBGAsYHQ/s400/mesinkasironlineapp%2B%25283%2529.png"
                    alt="aplikasi sekolah,e-learning cms sekolah"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">
                      Aplikasi Sekolah
                    </h3>
                    <Link to="/school" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-2YQ6jUJpxKc/YC9zI91OFII/AAAAAAAAM9E/iqlES3Qpw04uRWgydqDX3R7olqhBRw2OQCLcBGAsYHQ/s400/mesinkasironlineapp%2B%25287%2529.png"
                    alt="aplikasi bisnis,office,invoice akuntansi accounting"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">Aplikasi Office</h3>
                    <Link to="/office" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-7jtzvWNvy18/YC9zJmoftFI/AAAAAAAAM9I/8vyllB1_ut8l2lRaxl42thRJwbD2M15DACLcBGAsYHQ/s400/mesinkasironlineapp%2B%25288%2529.png"
                    alt="Pembuatan website bisnis company profile sekolah toko shop restoran cafe rumah makan"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">Web Design</h3>
                    <Link to="/website" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
    </div>
     <div className="col-12 col-md-12 p-3 p-md-5 text-center">
    <p>Slide kiri dan kanan untuk melihat layanan kami.</p>
        </div>
      </div>
    </div>
  );
};
export default Webapp;
