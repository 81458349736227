import React from "react";
const Contact = () => {
  return (
    <div className="row">
      <div className="col-12 col-md-12 p-3 p-md-5 uk-animation-toggle uk-animation-slide-right">
        <h3>Contact Us</h3>
        <div className="card bg-primary">
          <img
            src="https://1.bp.blogspot.com/-YVnCIS3UdIU/XzpJR9HByuI/AAAAAAAALEk/ECdc7iWQMIEMTh2seBIJoUB5hPJGjGziQCLcBGAsYHQ/s960/mesin%2Bkasir%2Btablet%2Bpos%2Bhybrid.jpg"
            className="card-img-top"
            alt="contact Kasir"
          />
          <div className="card-body">
            <p className="card-title">Contact</p>
            <p>
              <small>
                Whatsapp
                <br />
                +62895339403223
                <br />
                Call Us
                <br /> +62895339403223
                <br />
                Showroom
                <br /> Ruko Pasar Wisata Q.05-07
                <br />
                Juanda - Sidoarjo
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
