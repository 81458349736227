import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const OfficeList = ({ articles }) => (
  <div>
    <Helmet>
      <meta
        name="google-site-verification"
        content="ex4TnhMimzJT8jbjjo3HU_r-qfQczMhdYfALH1gyM2s"
      />
      <title>
        Aplikasi office kantor lengkap invoice inventori akutansi accounting
      </title>
      <meta
        content="Aplikasi office kantor lengkap invoice inventori akutansi accounting"
        property="og:title"
      />
      <meta
        content="Aplikasi office kantor lengkap invoice inventori akutansi accounting"
        name="facebook:title"
      />
      <meta
        content="Aplikasi office kantor lengkap invoice inventori akutansi accounting"
        property="og:site_name"
      />
      <meta
        content="Aplikasi office kantor lengkap invoice inventori akutansi accounting"
        name="twitter:title"
      />
      <meta
        content="Cari Aplikasi invoice , inventori software program accounting akuntansi lengkap hanya disini, murah meriah bonus website langsung online"
        name=" description"
      />
      <meta
        content="Cari Aplikasi invoice , inventori software program accounting akuntansi lengkap hanya disini, murah meriah bonus website langsung online"
        name="twitter:description"
      />
      <meta
        content="Cari Aplikasi invoice , inventori software program accounting akuntansi lengkap hanya disini, murah meriah bonus website langsung online"
        property="og:description"
      />
      <meta
        content="Cari Aplikasi invoice , inventori software program accounting akuntansi lengkap hanya disini, murah meriah bonus website langsung online"
        property="facebook:description"
      />
      <meta
        content="https://1.bp.blogspot.com/-oolLYoq-j5Q/X93ym6YAD8I/AAAAAAAALus/NZ7SFbMNI543KZaqFx129dX0ST4MBQAQACLcBGAsYHQ/w1200-h630-p-k-no-nu/TOUCHSCREEN3.png"
        property="og:image"
      />
      <meta content="https://axcora.com/getaxcoracms" property="og:author" />

      <meta content="website" property="og:type" />
      <meta
        content="https://www.facebook.com/mesinkasircomplete"
        property="facebook:author"
      />
      <meta
        content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
        name="facebook:image"
      />
      <meta content="summary_large_image" name="twitter:card" />
      <meta
        content="https://twitter.com/hockeycomp"
        property="twitter:author"
      />
      <meta content="@hockeycomp" name="twitter:site" />
      <meta
        content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
        name="twitter:image"
      />
      <meta content="index, follow" name="robots" />
    </Helmet>
    <div className="row uk-animation-toggle uk-animation-slide-bottom">
      <Link to="/webapp" className="btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          fill="currentColor"
          className="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>{" "}
        Back
      </Link>
      <div className="p-3 p-md-5 col-12 col-md-12 text-center">
        <h1>
          <strong>Office App</strong>
        </h1>
        <hr className="uk-divider-icon" />
        <p>Aplikasi invoice online include website company profile</p>
      </div>
      <div className="container">
        <div className="row">
          {articles.map((article, key) => (
            <div className="bg-danger border border-dark p-3 col-6 col-md-4 text-center rounded">
              <Link key={key} to={`/office/${article.name}`}>
                <img
                  key={article.img}
                  className="img-fluid"
                  alt="aplikasi restoran mesin kasir restoran online"
                  src={article.img}
                />
                <p>
                  <strong>
                    <small>{article.name}</small>
                  </strong>
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default OfficeList;
