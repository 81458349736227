import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Hello = () => {
  return (
    <div className="bg-dark">
      <Helmet>
        <meta
          name="google-site-verification"
          content="ex4TnhMimzJT8jbjjo3HU_r-qfQczMhdYfALH1gyM2s"
        />
        <title>Web App Website Applikasi Online </title>
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          name=" description"
        />
        <meta content="Web App Website Applikasi Online " property="og:title" />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          property="og:description"
        />
        <meta
          content="https://1.bp.blogspot.com/-oolLYoq-j5Q/X93ym6YAD8I/AAAAAAAALus/NZ7SFbMNI543KZaqFx129dX0ST4MBQAQACLcBGAsYHQ/w1200-h630-p-k-no-nu/TOUCHSCREEN3.png"
          property="og:image"
        />
        <meta content="https://axcora.com/getaxcoracms" property="og:author" />
        <meta
          content="Web App Website Applikasi Online "
          name="facebook:title"
        />
        <meta content="website" property="og:type" />
        <meta
          content="https://www.facebook.com/mesinkasircomplete"
          property="facebook:author"
        />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="facebook:image"
        />
        <meta
          content="Web App Website Applikasi Online "
          property="og:site_name"
        />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          property="facebook:description"
        />
        <meta content="summary_large_image" name="twitter:card" />
        <meta
          content="https://twitter.com/hockeycomp"
          property="twitter:author"
        />
        <meta content="@hockeycomp" name="twitter:site" />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="twitter:image"
        />
        <meta
          content="Aplikasi toko, aplikasi kasir, aplikasi invoice,sekolah online mesin kasir online pembuatan website all in one"
          name="twitter:description"
        />
        <meta content="Web App Website Applikasi Online" name="twitter:title" />
        <meta content="index, follow" name="robots" />
      </Helmet>
      <div className="row uk-animation-toggle uk-animation-scale-up">
        <div className="col-12 col-md-12 p-3 p-md-5 text-center">
          <h3>Hello World</h3>
          <hr className="uk-divider-icon" />
          <p>
            Saat nya kembangkan bisnis mu bersama kami untuk masa depan terbaik
            bagi revolusi digital.
          </p>
          <p>Pilih kebutuhan mu</p>
          <div className="row">
            <Link
              className="bg-danger border border-dark p-3 p-md-5 col-6 col-md-6 text-white rounded"
              to="/hardware"
            >
              <img
                className="img-fluid"
                alt="aplikasi web app"
                src="https://1.bp.blogspot.com/-FzgQbaqOzTE/YC980pJKxUI/AAAAAAAAM9k/ytdlcR52WqwEKAjNGwBAJ8Ik_vpQgguTQCLcBGAsYHQ/s96/icons8-imac-96.png"
              />
              <br />
              Hardware
            </Link>
            <Link
              className="bg-success border border-dark p-3 p-md-5 col-6 col-md-6 rounded"
              to="/webapp"
            >
              <img
                className="img-fluid"
                alt="aplikasi web app"
                src="https://1.bp.blogspot.com/-fU8FHWTxD2Q/YC980kO0IDI/AAAAAAAAM9g/D1CFjko4ffoHBL7tXA3L04WScj0MjHVugCLcBGAsYHQ/s96/icons8-android-96.png"
              />
              <br />
              Apps
            </Link>
          </div>
        </div>
     <div className="col-12 col-md-12 p-3 p-md-5 text-center">
        <p>
    Solusi Revolusi Digital anda, saat nya mulai sekarang dengan update digital apps bersama kami.
    </p>
    </div>
         <div className="col-12 col-md-12 p-3 p-md-5"></div>
         <div className="col-12 col-md-12 p-3 p-md-5"></div>
      </div>
    </div>
  );
};
export default Hello;
