import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Hardware = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="google-site-verification"
          content="ex4TnhMimzJT8jbjjo3HU_r-qfQczMhdYfALH1gyM2s"
        />
        <title>Cari Mesin Kasir lengkap hanya disini </title>
        <meta
          content="Jual mesin kasir online dan alarm security , mesin kasir toko, mesin kasir restoran touchscreen barcode lengkap"
          name=" description"
        />
        <meta
          content="Cari Mesin Kasir lengkap hanya disini "
          property="og:title"
        />
        <meta
          content="Jual mesin kasir online dan alarm security , mesin kasir toko, mesin kasir restoran touchscreen barcode lengkap"
          property="og:description"
        />
        <meta
          content="https://1.bp.blogspot.com/-oolLYoq-j5Q/X93ym6YAD8I/AAAAAAAALus/NZ7SFbMNI543KZaqFx129dX0ST4MBQAQACLcBGAsYHQ/w1200-h630-p-k-no-nu/TOUCHSCREEN3.png"
          property="og:image"
        />
        <meta content="https://axcora.com/getaxcoracms" property="og:author" />
        <meta
          content="Cari Mesin Kasir lengkap hanya disini"
          name="facebook:title"
        />
        <meta content="website" property="og:type" />
        <meta
          content="https://www.facebook.com/mesinkasircomplete"
          property="facebook:author"
        />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="facebook:image"
        />
        <meta
          content="Cari Mesin Kasir lengkap hanya disini"
          property="og:site_name"
        />
        <meta
          content="Jual mesin kasir online dan alarm security , mesin kasir toko, mesin kasir restoran touchscreen barcode lengkap"
          property="facebook:description"
        />
        <meta content="summary_large_image" name="twitter:card" />
        <meta
          content="https://twitter.com/hockeycomp"
          property="twitter:author"
        />
        <meta content="@hockeycomp" name="twitter:site" />
        <meta
          content="https://res.cloudinary.com/hockeycorp/image/upload/v1568369085/touchscreen_yonrpa.jpg"
          name="twitter:image"
        />
        <meta
          content="Jual mesin kasir online dan alarm security , mesin kasir toko, mesin kasir restoran touchscreen barcode lengkap"
          name="twitter:description"
        />
        <meta
          content="Cari Mesin Kasir lengkap hanya disini"
          name="twitter:title"
        />
        <meta content="index, follow" name="robots" />
      </Helmet>
      <div className="row uk-animation-toggle uk-animation-scale-down">
        <div className="col-12 col-md-12 p-3 p-md-5 text-center">
          <h3>Hardware</h3>
          <hr className="uk-divider-icon" />
          <p>Pilih Mesin Kasir dan Hardware POS display system</p>
          <div
            className="uk-position-relative uk-visible-toggle uk-dark p-3 p-md-5"
            tabIndex="-1"
            uk-slider="center: true"
          >
            <ul className="uk-slider-items uk-grid text-center">
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-fVeblO9nOc4/YC-hvdQg2OI/AAAAAAAAM-E/YYygAgcFR_gi2Af_4qgA0zIaXbrPjJjNACLcBGAsYHQ/s573/pembuatan%2Bwebsite.png"
                    alt="mesin kasir lengkap"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">
                      Mesin Kasir Lengkap
                    </h3>
                    <Link to="/mesinkasir" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-UH3cYdpCM8Q/YC-g876P_EI/AAAAAAAAM90/EvOtyyndewsLXAP3ronVlBlt7MQRm9ANQCLcBGAsYHQ/s567/ab1.png"
                    alt="Alarm security"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">Alarm Security</h3>
                    <Link to="/Alarm" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-_SdoxgwPOeQ/YC-iJRcBhlI/AAAAAAAAM-M/mvPMNsy-wzE8l3R_ucwwnEt5ajAzXMEXgCLcBGAsYHQ/s581/aplikasi.png"
                    alt="Price Chekcer alat cek harga"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">Price Checker</h3>
                    <Link to="/check" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-P0pi1s3g_gg/YC-iJZ12fMI/AAAAAAAAM-Q/fokjRpt1XpUOMbj7JhfSW4vOLikogs82wCLcBGAsYHQ/s221/aplikasisekolah%2B%25282%2529.png"
                    alt="Waiters tablet"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">Waiters Tabs</h3>
                    <Link to="/waiters" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
              <li className="uk-width-3-4">
                <div className="uk-panel">
                  <img
                    width="360"
                    src="https://1.bp.blogspot.com/-hHG6b3UmHAo/YC-iJchuN5I/AAAAAAAAM-U/8Oxxk5Rc5Ag4w8IFx_8ourDZNRihqbCtwCLcBGAsYHQ/s220/aplikasisekolah%2B%25283%2529.png"
                    alt="Kitchen Display"
                  />
                  <div
                    className="uk-position-bottom uk-panel"
                    style={{
                      backgroundColor: "",
                      opacity: 0.9
                    }}
                  >
                    <h3 className="text-danger p-1 rounded">Kitchen Display</h3>
                    <Link to="/kitchen" className="btn btn-primary">
                      More info
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
         <div className="col-12 col-md-12 p-3 p-md-5 text-center">
    <p>Slide kiri dan kanan untuk pilihan produk</p>
    </div>
      </div>
    </div>
  );
};
export default Hardware;
